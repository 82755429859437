import styled from 'styled-components';

const HistoryStyles = styled.div`
  .history__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 50px;

    @media (max-width: 1100px) {
      flex-direction: column-reverse;
      justify-content: center;
      padding-bottom: 0;
    }
  }

  .historyImgae__container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .history-store {
    height: 326px;
    width: 452px;

    @media (max-width: 1100px) {
      width: 100%;
      height: auto;
    }
  }

  .history-store2 {
    z-index: 100;
    margin-top: -30px;
    margin-left: 77px;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .story__container {
    font-size: 16px;
    line-height: 200%;
    text-align: left;
    max-width: 577px;
    padding: 30px;

    h1 {
      color: ${props => props.theme.orange};
      font-size: 35px;
      font-weight: 800;
      line-height: 48px;
      padding-bottom: 24px;

      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 41px;
      }
    }

    p {
      margin-bottom: 20px;
    }

    @media (max-width: 1100px) {
      max-width: 100%;
    }
  }

  .purpose__container {
    background-color: ${props => props.theme.gray};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .purpose__subHeader {
    font-size: 25px;
    font-weight: 800;
    line-height: 34px;
  }

  .list__container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    padding: 20px;

    @media (max-width: 768px) {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
    }
  }

  .number {
    color: ${props => props.theme.orange};
    font-size: 35px;
    font-weight: 800;
    line-height: 48px;
    padding-right: 0.5em;
  }

  ul {
    margin: 0;
    padding: 0 20px;
  }

  li {
    display: flex;
    font-size: 16px;
    line-height: 200%;
    max-width: 440px;
    padding-bottom: 40px;
  }

  .bold-heaeder {
    font-weight: bold;
  }
`;

export default HistoryStyles;
