import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import VendorPartnerLogos from '../components/vendor-partner-logos';

// images
import HistoryStore from '../images/history.png';
import HistoryStore2 from '../images/historical2.jpg';

// styled components
import Title from '../components/styles/title';
import HistoryStyles from '../components/styles/history-styles';

const History = () => (
    <Layout>
        <SEO title="History" />
        <HistoryStyles>
            <div className="history__container">
                <div className="historyImgae__container">
                    <img
                        className="history-store"
                        src={HistoryStore}
                        alt="Historic store front"
                    />
                    <img
                        className="history-store2"
                        src={HistoryStore2}
                        alt="Historical store front"
                    />
                </div>
                <div className="story__container">
                    <h1>History</h1>
                    <p>
                        The Independent Organization of Little Caesars
                        Franchisees (IOLCF) was formed in 1997 by the
                        franchisees of Little Caesars Enterprises.
                    </p>
                    <p>
                        In 2001 the organization was responsible for negotiating
                        a class settlement that restructured the relationship
                        between the Corporation and its’ franchisees. From that
                        settlement significant rights were established for the
                        franchisees. National advertising was abolished and the
                        right to purchase food and supplies from an outside
                        vendor was gained for franchisees in the system prior to
                        Sept. 1, 2001.
                    </p>
                    <p>
                        From 2004 – 2018 the Association purchased and
                        distributed supplies to Little Caesar franchisees
                        through the Advanced Purchasing Cooperative (APC).
                    </p>
                    <p>
                        As a result of the Settlement Agreement two committees
                        were formed made up of six organization members and six
                        corporate personnel:
                    </p>
                    <p>
                        The{' '}
                        <span className="bold-heaeder">
                            Marketing Committee
                        </span>{' '}
                        provides input into all future advertising campaigns and
                        has oversight responsibility of our national advertising
                        funds (Caesar Fund).
                        <br />
                        The{' '}
                        <span className="bold-heaeder">
                            Operations Committee
                        </span>{' '}
                        provides input into store-level operations, product
                        specs, distribution and changes to the Franchise
                        Agreement.
                    </p>
                    <p>
                        As of Jan. 1, 2022 most of the agreed upon requirements
                        of the Settlement Agreement have now expired however,
                        many of the provisions remain historically in place and
                        function as much needed communication channels between
                        LCE and its franchisees.
                    </p>
                    <p>
                        The IOLCF continues to elect from its membership
                        franchisees to serve on the various committees and
                        promote the best interests of the Little Caesar
                        franchisee.
                    </p>
                </div>
            </div>

            <div className="purpose__container">
                <Title>Our Purpose...</Title>
                <p className="purpose__subHeader">
                    ...to help your business succeed
                </p>
                <div className="list__container">
                    <ul>
                        <li>
                            <p className="number">1</p>
                            Articulate and advocate the needs, interests and
                            goals of its Members in the context of a
                            constructive and cooperative relationship with their
                            franchisor
                        </li>
                        <li>
                            <p className="number">2</p>
                            Protect and enhance the economic investments of
                            Little Caesars franchisees
                        </li>
                        <li>
                            <p className="number">3</p>
                            Leverage group purchasing power to provide programs
                            economically beneficial to its Members
                        </li>
                        <li>
                            <p className="number">4</p>
                            Unite franchisees in a national, system-wide
                            association to consider, agree and act in unison
                            upon all matters affecting the activities and
                            businesses of the Members
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <p className="number">5</p>
                            Foster and promote the interests of those
                            individuals and entities who are franchisees of
                            Little Caesars Enterprises, Inc.
                        </li>
                        <li>
                            <p className="number">6</p>
                            Cooperate for the improvement of the legal, business
                            and financial conditions relating to such
                            franchisees
                        </li>
                        <li>
                            <p className="number">7</p>
                            Secure, preserve, analyze, disseminate and
                            distribute accurate and reliable information for its
                            Members
                        </li>
                        <li>
                            <p className="number">8</p>
                            Articulate and advocate the needs and interests of
                            its Members before legislative, administrative and
                            judicial branches of local, state, and national
                            governments
                        </li>
                    </ul>
                </div>
            </div>
        </HistoryStyles>
        <VendorPartnerLogos />
    </Layout>
);

export default History;
